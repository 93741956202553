import React, {Component} from "react";
import {Row, Col, Card, CardTitle, CardText, CardBody, Container, Button, Badge, ButtonGroup, Input} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';
import {AvForm, AvField} from 'availity-reactstrap-validation';

import {
    editClientExtraDataTicket,
    editClientTicket,
    infoClientTicket,
    listClientTickets,
    refundClientTicket
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {windowOpen} from "echarts/src/util/format";
import spid_logo from "../../../assets/images/spid-level2-logo-lb.png";

var moment = require('moment');

class TicketClientListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "[Ticket] Utente", field: "client", width: 320, renderCell: (params) => (
                        <Row>
                            <Col>[{params.id}] {params.value !== null ? params.value.email : "Utente anonimo"}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Nome e cognome",
                    field: "client1",
                    width: 240,
                    renderCell: (params) => params.getValue(params.id, "client") !== null ? (
                        <Row>
                            <Col>{params.getValue(params.id, "client").firstname} {params.getValue(params.id, "client").lastname}</Col>
                        </Row>
                    ) : <></>
                },
                {
                    headerName: "Nominativo",
                    field: "nickname",
                    width: 240,
                },
                {
                    headerName: "Ticket", field: "ticket", width: 160, renderCell: (params) => (
                        <Row>
                            <Col>{params.value.name}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Data Acquisto", field: "date_purchase", width: 180
                },
                {
                    headerName: "Stato Pagamento", field: "status_payment", width: 200, renderCell: (params) => {
                        switch (params.value) {
                            case 'STATUS_PAYED':
                                return <Badge color="success">PAGATO</Badge>;
                            case 'STATUS_TO_PAY':
                                return <Badge color="info">DA PAGARE</Badge>;
                            case 'STATUS_CANCELLED':
                                return <Badge color="danger">CANCELLATO</Badge>;
                            case 'STATUS_REFUNDED':
                                return <Badge color="warning">RIMBORSATO</Badge>;
                        }
                    }
                },
                {
                    headerName: "Stato", field: "is_used", width: 180, renderCell: (params) =>
                        ((params.value) ? <Badge color="success">Usato</Badge> :
                                <Badge color="danger">NON usato</Badge>
                        )
                },
                {
                    headerName: " ",
                    width: 100,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.infoButton(params.id)} type="button" color="info">Info</Button>
                    )
                }
            ],
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            markers: [],
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            form_edit_ticket: {
                date_start: null,
                date_end: null,
            },
            search: null,
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_start_use: null,
            date_end_use: null,
            date_current: new Date(),
            ticket: null,
            sessions: [],
            tmp_input: null
        };
    }

    setDate = (date, type) => {
        console.log(date);
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    setDateTicket = (date, type) => {
        let date_start = this.state.date_start_use;
        let date_end = this.state.date_end_use;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit_ticket = this.state.form_edit_ticket;
        form_edit_ticket.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit_ticket.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start_use: date_start, date_end_use: date_end, form_edit_ticket: form_edit_ticket});
    }

    async componentDidMount() {
        if (typeof this.props.match.params.type !== "undefined" && this.props.match.params.type !== null) {
            let date_start = this.state.date_start;
            let date_end = this.state.date_end;
            switch (this.props.match.params.type) {
                case 'DAILY':
                    date_start = moment.parseZone(new Date());
                    date_end = moment.parseZone(new Date());
                    break;
                case 'TWODAYS':
                    date_start = moment.parseZone(new Date()).subtract(1, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'THREEDAYS':
                    date_start = moment.parseZone(new Date()).subtract(2, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'FOURDAYS':
                    date_start = moment.parseZone(new Date()).subtract(3, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case "WEEKDAYS":
                    date_start = moment.parseZone(new Date()).subtract(4, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'WEEKLY':
                    date_start = moment.parseZone(new Date()).subtract(6, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'TWOWEEKS':
                    date_start = moment.parseZone(new Date()).subtract(13, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'MONTHLY':
                    date_start = moment.parseZone(new Date()).subtract(26, 'days');
                    date_end = moment.parseZone(new Date()).add(4, 'days');
                    break;
                case 'TRIMESTER':
                    date_start = moment.parseZone(new Date()).subtract(26, 'days');
                    date_end = moment.parseZone(new Date()).add(45, 'days');
                    break;
                case "SEASONAL":
                case 'SEMIANNUAL':
                    date_start = moment.parseZone(new Date()).subtract(100, 'days');
                    date_end = moment.parseZone(new Date()).add(100, 'days');
                    break;
                case 'YEARLY':
                    date_start = moment.parseZone(new Date()).subtract(176, 'days');
                    date_end = moment.parseZone(new Date()).add(189, 'days');
                    break;
                case 'BIANNUAL':
                    date_start = moment.parseZone(new Date()).subtract(176, 'days');
                    date_end = moment.parseZone(new Date()).add(189, 'days');
                    break;
                case 'TRIENNIAL':
                    date_start = moment.parseZone(new Date()).subtract(176, 'days');
                    date_end = moment.parseZone(new Date()).add(189, 'days');
                    break;
            }
            let form_edit = {
                date_start: date_start.local().format("DD/MM/YYYY"),
                date_end: date_end.local().format("DD/MM/YYYY"),
            }
            this.setState({
                date_start: new Date(date_start),
                date_end: new Date(date_end),
                form_edit: form_edit
            }, () => this.loadList(0));
        } else {
            this.loadList(0);
        }
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        if (this.state.search !== null)
            data.search = this.state.search;
        listClientTickets(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    async refundTicket(id) {
        let res = await refundClientTicket(id);
        if (res) {
            global.notify("Rimborso effettuato con successo", "success");
            this.setState({ticket: null, sessions: []}, () => this.loadList(0));
        } else {
            global.notify("Errore con il rimborso", "danger");
            this.loadList(0)
        }
    }

    async infoButton(params) {
        let res = await infoClientTicket(params);

        if (Object.keys(res.sessions).length === 0) {
            this.setState({ticket: res.ticket, sessions: []});
        }
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];
            sessions.push({
                id: session.id,
                client_spid: typeof session.client_spid !== "undefined" ? session.client_spid : null,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: res.captures.length,
                fishes: res.captures
            });
        }
        let ticket = res.ticket;
        let form_edit = {date_start: null, date_end: null};
        if (ticket.date_start_use !== null) {
            this.setState({date_start_use: moment(ticket.date_start_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_start = moment(ticket.date_start_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        if (ticket.date_end_use !== null) {
            this.setState({date_end_use: moment(ticket.date_end_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_end = moment(ticket.date_end_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        let nickname = null;
        if (ticket.nickname !== null)
            nickname = res.ticket.nickname;
        this.setState({ticket: ticket, sessions: sessions, form_edit_ticket: form_edit, tmp_input: nickname});
    }

    async handleValidSubmit(event, values, edit = false) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList(0));
    }

    async handleEditExtraDataSubmit(event, values, edit = false) {
        editClientExtraDataTicket({id: this.state.ticket.id, extra_data: JSON.stringify(values)}).then((res) => {
            if (typeof res.error !== 'undefined')
                global.notify("I dati extra non sono stati modificati: " + res.error, "danger");
            else {
                global.notify("Ticket modificato con successo", "success");
                this.infoButton(this.state.ticket.id);
            }
        })
    }

    renderSessions = () => {
        return this.state.sessions.map((session, i) => {
            let spid = null;
            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <Card className={"col-md-12 col-sm-12"} body>
                    <CardTitle tag="h5">{session.name} - {session.data}</CardTitle>
                    <div className={"row"}>
                        <div className={'col-md-6'}>
                            <CardText>
                                Data avvio: {session.date_start}<br/>
                                Data chiusura: {session.date_end}<br/>
                                Area: {session.area}<br/>
                                MicroArea: {session.micro_area}<br/>
                                Catture: {session.count}<br/>
                            </CardText>
                        </div>
                        <div className={'col-md-6'}>
                            <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                Controllato
                                da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                            </CardText>
                        </div>
                        {spid}
                    </div>
                    <Row>
                        {session.fishes.map((fish, iFish) => {
                            return (
                                <div className={"col-md-2 col-sm-12"}>
                                    <Card body>
                                        <CardTitle
                                            tag="h5">{fish.fish.name}</CardTitle>
                                        <CardText>
                                            Data: <b>{fish.date_capture}</b><br/>
                                            {(fish.killed) ?
                                                <Badge color="info">KILL</Badge> :
                                                <Badge color="warning">NO KILL</Badge>}<br/>
                                            Lunghezza: {fish.length}<br/>
                                            Peso: {fish.weight}<br/>
                                        </CardText>
                                    </Card>
                                </div>
                            )
                        })}
                    </Row>
                </Card>
            )
        })
    }

    downloadTicket = (id) => {
        global.notify("Ticket scaricato", "success");
        windowOpen(global.baseUrl + 'admin/ticket/download/' + id, "blank")
    }

    editTicket = async () => {
        let data = {
            id: this.state.ticket.id,
            nickname: this.state.tmp_input,
            date_start: this.state.form_edit_ticket.date_start,
            date_end: this.state.form_edit_ticket.date_end
        };

        console.log(data);
        let res = await editClientTicket(data);
        if (typeof res.error !== 'undefined')
            global.notify("Il ticket non è stato modificato: " + res.error, "danger");
        else {
            global.notify("Ticket modificato con successo", "success");
            this.infoButton(this.state.ticket.id);
        }
    }

    renderInfoTicket = () => {
        if (this.state.ticket === null)
            return;
        let ticket = this.state.ticket;
        let payment = null;
        if (ticket.paypal !== null)
            payment = <div>
                <hr/>
                <b>Pagato con PayPal</b><br/>Codice
                transazione: {ticket.paypal.sale_id}<br/>Token: {ticket.paypal.token}</div>
        if (ticket.stripe !== null)
            payment = <div>
                <hr/>
                <b>Pagato con Stripe</b><br/>Codice transazione: {ticket.stripe.stripe_id}<br/>Codice
                cliente: {ticket.stripe.client_secret}</div>

        let extra_data = [];
        if (Object.keys(ticket.extra_data).length > 0)
            extra_data.push(<hr/>);
        for (let i in ticket.extra_data) {
            let data = ticket.extra_data[i];
            extra_data.push(<b>{data.name}:</b>);
            extra_data.push(<span> {data.value}</span>);
            extra_data.push(<br/>);
        }
        let total = 0;
        let summary = [<hr/>];
        summary.push(<><b>Ticket:</b> €{ticket.price.toFixed(2)}<br/></>);
        total = ticket.price;
        if (ticket.service_fee !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Commissione:</b> €{ticket.service_fee.toFixed(2)}</span></>);
            total += ticket.service_fee;
        }
        if (ticket.discount_price !== null) {
            let code = "";
            if (ticket.coupon !== null)
                code = " (" + ticket.coupon.code + ")";
            summary.push(<><span style={{textWrap: "nowrap"}}><b>Sconto:</b> -€{ticket.discount_price.toFixed(2)} {code}</span><br/></>);
            total -= ticket.discount_price;
        }
        if (ticket.fees !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Transazione pagamento:</b> €{ticket.fees.toFixed(2)}</span><br/></>);
        }
        summary.push(<><b>Totale:</b> €{total.toFixed(2)}<br/></>);
        let form_extra_data = null;
        if (Object.keys(ticket.ticket.extra_data).length > 0) {
            let inputs = [];
            for (let i in ticket.ticket.extra_data) {
                let extra_data = ticket.ticket.extra_data[i];
                let value = "";
                for (let x in ticket.extra_data) {
                    if (ticket.extra_data[x].id === extra_data.id)
                        value = ticket.extra_data[x].value;
                }
                inputs.push(<div className="form-group col-md-12">
                    <AvField name={extra_data.id} label={extra_data.name} value={value}
                             className="form-control"
                             placeholder={extra_data.name}
                             type="text"/>
                </div>)
            }

            form_extra_data = <Card><CardBody><CardTitle tag="h5">Modifica dati extra del ticket</CardTitle>
                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                    this.handleEditExtraDataSubmit(e, v, this.state.flag_edit)
                }} ref={c => (this.form = c)}>
                    <div className={"row"}>
                        {inputs}
                        <div className="form-group col-md-12" align={"center"}>
                            <Button type="submit" color="success">Salva</Button>
                        </div>
                    </div>
                </AvForm>
            </CardBody></Card>
        }

        return <div className={"row"}>
            <div className={"col-md-6"}>
                <Card body>
                    <CardTitle tag="h5">Ticket #{ticket.id}</CardTitle>
                    <CardText>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <b>Nome ticket:</b> {ticket.ticket.name}<br/>
                                Data acquisto: {ticket.date_purchase}<br/>
                                Data utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                                {ticket.client !== null ? "Utente: " + ticket.client.firstname + " " + ticket.client.lastname : ""}<br/>
                                {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                                {summary}
                                {extra_data}
                                {payment}
                                <hr/>
                                <b>Ente:</b> {ticket.ticket.society !== null ? ticket.ticket.society.name : "-"}<br/>
                                <b>Area:</b> {ticket.ticket.area !== null ? ticket.ticket.area.name : "-"} <br/>
                                {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            </div>
                            <div className={"col-md-6"} align={"right"}>
                                <Button onClick={() => this.downloadTicket(ticket.id)}
                                        color={"danger"}>Scarica pdf</Button>
                                <br/>
                                <br/>
                                <Button onClick={() => this.refundTicket(ticket.id)}
                                        color={"warning"}>Rimborsa ticket</Button>
                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
            <div className={"col-md-6"}>
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">Modifica dati del ticket</CardTitle>
                        <div className={"row"}>
                            <div className="form-group col-md-6">
                                <label>Data inizio utilizzo</label>
                                <DatePicker selected={this.state.date_start_use} startDate={this.state.date_current}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "start")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data inizio utilizzo"}/>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Data fine utilizzo</label>
                                <DatePicker selected={this.state.date_end_use} minDate={this.state.date_start_use}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "end")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data fine utilizzo"}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Nominativo</label>
                                <Input type="text" value={this.state.tmp_input} onChange={(e) => {
                                    this.setState({tmp_input: e.target.value});
                                }} placeholder={"Nominativo del ticket"}/>
                            </div>
                            <div align={"center"} class={"col-md-12"}>
                                <Button onClick={() => this.editTicket()} color={"success"}>Salva</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {form_extra_data}
            </div>
        </div>
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Tickets"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli le date in cui vuoi visualizzare i ticket</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => this.loadList(0)}
                                                        color={"success"}>Aggiorna</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca ticket"
                                                     className="form-control"
                                                     placeholder="Cerca ticket per nome, cognome o email dei clienti"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>

                                <div className="form-group">
                                    <div style={{height: '50vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                                {this.renderInfoTicket()}
                                {this.renderSessions()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {listClientTickets})(TicketClientListPage));
