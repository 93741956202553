import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup, Label, CardText, Badge, CardTitle, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    infoSociety,
    getListAreas,
    listTickets,
    refundClientTicket,
    infoClientTicket,
    listClientTickets,
    editClientTicket,
    addReferral, listReferralSociety, editReferral, deleteReferral, editClientExtraDataTicket
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import DatePicker from "react-datepicker";
import {windowOpen} from "echarts/src/util/format";
import spid_logo from "../../assets/images/spid-level2-logo-lb.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

var moment = require('moment');

class SocietySummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnsAreas: [
                {headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "Numero", field: "number", width: 140},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140},
                {
                    headerName: " ",
                    width: 120,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => window.location.href = "/zone/edit/" + params.id} type="button"
                                color="primary">Modifica</Button>
                    )
                }
            ],
            rowsAreas: [],
            paginatorAreas: {loading: true, countRows: 0, limit: 50},
            showAreas: true,
            columnsTickets: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, renderCell: (params) => {
                        let name = [];
                        if (params.getValue(params.id, "is_hidden"))
                            name.push(<FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/>);
                        if (params.getValue(params.id, "is_disabled"))
                            name.push(<FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/>);
                        name.push(<span>{params.value}</span>);
                        return name;
                    }
                },
                {
                    headerName: "Area", field: "areaName", width: 140, valueGetter: (params) =>
                        (params.getValue(params.id, "area").name !== null ? params.getValue(params.id, "area").name : "") + " " +
                        (params.getValue(params.id, "micro_area") !== null ? params.getValue(params.id, "micro_area").name : "")
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 140, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "number", width: 180, renderCell: (params) => {
                        if (params.getValue(params.id, "ticket_residence_type") === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.getValue(params.id, "ticket_residence_type").name}</Badge>)
                    }
                },
                {
                    headerName: " ",
                    width: 200,
                    field: 'id',
                    renderCell: (params) => <Button type="button"
                                                    onClick={() => window.location.href = '/ticket/edit/' + params.id}
                                                    color="primary">Modifica</Button>
                }
            ],
            rowsTickets: [],
            paginatorTickets: {loading: true, countRows: 0, limit: 50},
            showTickets: true,

            columnsTicketsClient: [
                {
                    headerName: "[Ticket] Utente", field: "client", width: 320, renderCell: (params) => (
                        <Row>
                            <Col>[{params.id}] {params.value !== null ? params.value.email : "Utente anonimo"}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Nome e cognome",
                    field: "client1",
                    width: 320,
                    renderCell: (params) => params.getValue(params.id, "client") !== null ? (
                        <Row>
                            <Col>{params.getValue(params.id, "client").firstname} {params.getValue(params.id, "client").lastname}</Col>
                        </Row>
                    ) : <></>
                },
                {
                    headerName: "Ticket", field: "ticket", width: 160, renderCell: (params) => (
                        <Row>
                            <Col>{params.value.name}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Data Acquisto", field: "date_purchase", width: 180
                },
                {
                    headerName: "Stato Pagamento", field: "status_payment", width: 200, renderCell: (params) => {
                        switch (params.value) {
                            case 'STATUS_PAYED':
                                return <Badge color="success">PAGATO</Badge>;
                            case 'STATUS_TO_PAY':
                                return <Badge color="info">DA PAGARE</Badge>;
                            case 'STATUS_CANCELLED':
                                return <Badge color="danger">CANCELLATO</Badge>;
                            case 'STATUS_REFUNDED':
                                return <Badge color="warning">RIMBORSATO</Badge>;
                        }
                    }
                },
                {
                    headerName: "Stato", field: "is_used", width: 180, renderCell: (params) =>
                        ((params.value) ? <Badge color="success">Usato</Badge> :
                                <Badge color="danger">NON usato</Badge>
                        )
                },
                {
                    headerName: " ",
                    width: 100,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.infoButton(params.id)} type="button" color="info">Info</Button>
                    )
                }
            ],
            rowsTicketsClient: [],
            paginatorTicketsClient: {loading: true, countRows: 0, limit: 50},
            society: null,
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            form_edit_ticket: {
                date_start: null,
                date_end: null,
            },
            search: null,
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_current: new Date(),
            ticket: null,
            sessions: [],
            date_start_use: null,
            date_end_use: null,
            tmp_input: null,
            referrals: [],
            referral_edit: null,
            referral_edit_percentage: null
        };
    }

    async componentDidMount() {
        infoSociety(this.props.match.params.id).then((result) => {
            if (typeof result.error === 'undefined') {
                this.setState({society: result.society}, () => {
                    this.loadListAreas(result.society.id);
                    this.loadListTickets(result.society.id);
                    this.loadList(result.society.id, 0);
                    this.loadListReferral();
                });
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    renderSociety() {
        if (this.state.society === null)
            return;
        let society = this.state.society;
        let text = society.name;
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}<br/><Button type="button"
                                            onClick={() => window.location.href = '/societySettings/' + society.id}
                                            color="primary">Impostazioni</Button></CardText>
    }

    loadListTickets = (id_society, page = 0) => {
        let paginator = this.state.paginatorTickets;
        paginator.loading = true;
        this.setState({paginatorTickets: paginator});

        listTickets(id_society, page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsTickets: [], paginatorTickets: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsTickets: result.list, paginatorTickets: paginator});
            }
        })
    }

    handlePageChangeTickets = (params) => {
        this.loadListTickets(this.state.society.id, params.page)
    };

    loadListAreas = (id_society, page = 0) => {
        let paginator = this.state.paginatorAreas;
        paginator.loading = true;
        this.setState({paginatorAreas: paginator});

        getListAreas(page + 1, null, id_society).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsAreas: [], paginatorAreas: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsAreas: result.list, paginatorAreas: paginator});
            }
        })
    }

    handlePageChangeAreas = (params) => {
        this.loadListAreas(this.state.society.id, params.page)
    };


    loadList = (id_society, page) => {
        let paginator = this.state.paginatorTicketsClient;
        paginator.loading = true;
        this.setState({paginatorTicketsClient: paginator});
        let data = {
            id_society: id_society,
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        if (this.state.search !== null)
            data.search = this.state.search;
        listClientTickets(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsTicketsClient: [], paginatorTicketsClient: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsTicketsClient: result.list, paginatorTicketsClient: paginator});
            }
        })
    }

    async refundTicket(id) {
        let res = await refundClientTicket(id);
        if (res) {
            global.notify("Rimborso effettuato con successo", "success");
            this.setState({ticket: null, sessions: []}, () => this.loadList(this.state.society.id, 0));
        } else {
            global.notify("Errore con il rimborso", "danger");
            this.loadList(this.state.society.id, 0)
        }
    }

    async infoButton(params) {
        let res = await infoClientTicket(params);

        if (Object.keys(res.sessions).length === 0) {
            this.setState({ticket: res.ticket, sessions: []});
        }
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];
            sessions.push({
                id: session.id,
                client_spid: typeof session.client_spid !== "undefined" ? session.client_spid : null,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: res.captures.length,
                fishes: res.captures
            });
        }
        let ticket = res.ticket;
        let form_edit = {date_start: null, date_end: null};
        if (ticket.date_start_use !== null) {
            this.setState({date_start_use: moment(ticket.date_start_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_start = moment(ticket.date_start_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        if (ticket.date_end_use !== null) {
            this.setState({date_end_use: moment(ticket.date_end_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_end = moment(ticket.date_end_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        let nickname = null;
        if (ticket.nickname !== null)
            nickname = res.ticket.nickname;
        this.setState({ticket: ticket, sessions: sessions, form_edit_ticket: form_edit, tmp_input: nickname});
    }

    setDate = (date, type) => {
        console.log(date);
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    async handleValidSubmit(event, values, edit = false) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList(this.state.society.id, 0));
    }


    async handleValidSubmitReferral(event, values) {
        addReferral(this.state.society.id, values.referral_percentage).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify("Codice creato con successo", "success");
                this.formReferral && this.formReferral.reset();
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
        this.loadListReferral();
    }

    loadListReferral = () => {
        listReferralSociety(this.state.society.id).then(result => {
            if (!result) {
                this.setState({referrals: []});
            } else {
                this.setState({referrals: result.list});
            }
        })
    }

    editReferral(id_referral, percentage) {
        editReferral(id_referral, percentage).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify("Codice modificato con successo", "success");
                this.handleClose();
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });

        this.loadListReferral();
    }

    deleteReferral(id) {
        deleteReferral(id).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify("Codice eliminato con successo", "success");
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
        this.loadListReferral();
    }

    renderSessions = () => {
        return this.state.sessions.map((session, i) => {
            let spid = null;
            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <Card className={"col-md-12 col-sm-12"} body>
                    <CardTitle tag="h5">{session.name} - {session.date_start}</CardTitle>
                    <div className={"row"}>
                        <div className={'col-md-6'}>
                            <CardText>
                                Data avvio: {session.date_start}<br/>
                                Data chiusura: {session.date_end}<br/>
                                Area: {session.area}<br/>
                                MicroArea: {session.micro_area}<br/>
                                Catture: {session.count}<br/>
                            </CardText>
                        </div>
                        <div className={'col-md-6'}>
                            <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                Controllato
                                da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                            </CardText>
                        </div>
                        {spid}
                    </div>
                    <Row>
                        {session.fishes.map((fish, iFish) => {
                            return (
                                <div className={"col-md-2 col-sm-12"}>
                                    <Card body>
                                        <CardTitle
                                            tag="h5">{fish.fish.name}</CardTitle>
                                        <CardText>
                                            Data: <b>{fish.date_capture}</b><br/>
                                            {(fish.killed) ?
                                                <Badge color="info">KILL</Badge> :
                                                <Badge color="warning">NO KILL</Badge>}<br/>
                                            Lunghezza: {fish.length}<br/>
                                            Peso: {fish.weight}<br/>
                                        </CardText>
                                    </Card>
                                </div>
                            )
                        })}
                    </Row>
                </Card>
            )
        })
    }

    downloadTicket = (id) => {
        global.notify("Ticket scaricato", "success");
        windowOpen(global.baseUrl + 'admin/ticket/download/' + id, "blank")
    }

    setDateTicket = (date, type) => {
        let date_start = this.state.date_start_use;
        let date_end = this.state.date_end_use;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit_ticket = this.state.form_edit_ticket;
        form_edit_ticket.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit_ticket.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start_use: date_start, date_end_use: date_end, form_edit_ticket: form_edit_ticket});
    }

    editTicket = async () => {
        let data = {
            id: this.state.ticket.id,
            nickname: this.state.tmp_input,
            date_start: this.state.form_edit_ticket.date_start,
            date_end: this.state.form_edit_ticket.date_end
        };

        console.log(data);
        let res = await editClientTicket(data);
        if (typeof res.error !== 'undefined')
            global.notify("Il ticket non è stato modificato: " + res.error, "danger");
        else {
            global.notify("Ticket modificato con successo", "success");
            this.infoButton(this.state.ticket.id);
        }
    }

    async handleEditExtraDataSubmit(event, values, edit = false) {
        editClientExtraDataTicket({id: this.state.ticket.id, extra_data: JSON.stringify(values)}).then((res) => {
            if (typeof res.error !== 'undefined')
                global.notify("I dati extra non sono stati modificati: " + res.error, "danger");
            else {
                global.notify("Ticket modificato con successo", "success");
                this.infoButton(this.state.ticket.id);
            }
        })
    }

    renderInfoTicket = () => {
        if (this.state.ticket === null)
            return;
        let ticket = this.state.ticket;
        let payment = null;
        if (ticket.paypal !== null)
            payment = <div>
                <hr/>
                <b>Pagato con PayPal</b><br/>Codice
                transazione: {ticket.paypal.sale_id}<br/>Token: {ticket.paypal.token}</div>
        if (ticket.stripe !== null)
            payment = <div>
                <hr/>
                <b>Pagato con Stripe</b><br/>Codice transazione: {ticket.stripe.stripe_id}<br/>Codice
                cliente: {ticket.stripe.client_secret}</div>

        let extra_data = [];
        if (Object.keys(ticket.extra_data).length > 0)
            extra_data.push(<hr/>);
        for (let i in ticket.extra_data) {
            let data = ticket.extra_data[i];
            extra_data.push(<b>{data.name}:</b>);
            extra_data.push(<span> {data.value}</span>);
            extra_data.push(<br/>);
        }
        let total = 0;
        let summary = [<hr/>];
        summary.push(<><b>Ticket:</b> €{ticket.price.toFixed(2)}<br/></>);
        total = ticket.price;
        if (ticket.service_fee !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Commissione:</b> €{ticket.service_fee.toFixed(2)}</span></>);
            total += ticket.service_fee;
        }
        if (ticket.discount_price !== null) {
            let code = "";
            if (ticket.coupon !== null)
                code = " (" + ticket.coupon.code + ")";
            summary.push(<><span style={{textWrap: "nowrap"}}><b>Sconto:</b> -€{ticket.discount_price.toFixed(2)} {code}</span><br/></>);
            total -= ticket.discount_price;
        }
        if (ticket.fees !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Transazione pagamento:</b> €{ticket.fees.toFixed(2)}</span><br/></>);
        }
        summary.push(<><b>Totale:</b> €{total.toFixed(2)}<br/></>);
        let form_extra_data = null;
        if (Object.keys(ticket.ticket.extra_data).length > 0) {
            let inputs = [];
            for (let i in ticket.ticket.extra_data) {
                let extra_data = ticket.ticket.extra_data[i];
                let value = "";
                for (let x in ticket.extra_data) {
                    if (ticket.extra_data[x].id === extra_data.id)
                        value = ticket.extra_data[x].value;
                }
                inputs.push(<div className="form-group col-md-12">
                    <AvField name={extra_data.id} label={extra_data.name} value={value}
                             className="form-control"
                             placeholder={extra_data.name}
                             type="text"/>
                </div>)
            }

            form_extra_data = <Card><CardBody><CardTitle tag="h5">Modifica dati extra del ticket</CardTitle>
                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                    this.handleEditExtraDataSubmit(e, v, this.state.flag_edit)
                }} ref={c => (this.form = c)}>
                    <div className={"row"}>
                        {inputs}
                        <div className="form-group col-md-12" align={"center"}>
                            <Button type="submit" color="success">Salva</Button>
                        </div>
                    </div>
                </AvForm>
            </CardBody></Card>
        }

        return <div className={"row"}>
            <div className={"col-md-6"}>
                <Card body>
                    <CardTitle tag="h5">Ticket #{ticket.id}</CardTitle>
                    <CardText>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <b>Nome ticket:</b> {ticket.ticket.name}<br/>
                                Data acquisto: {ticket.date_purchase}<br/>
                                Data utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                                {ticket.client !== null ? "Utente: " + ticket.client.firstname + " " + ticket.client.lastname : ""}<br/>
                                {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                                {summary}
                                {extra_data}
                                {payment}
                                <hr/>
                                <b>Ente:</b> {ticket.ticket.society !== null ? ticket.ticket.society.name : "-"}<br/>
                                <b>Area:</b> {ticket.ticket.area !== null ? ticket.ticket.area.name : "-"} <br/>
                                {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            </div>
                            <div className={"col-md-6"} align={"right"}>
                                <Button onClick={() => this.downloadTicket(ticket.id)}
                                        color={"danger"}>Scarica pdf</Button>
                                <br/>
                                <br/>
                                <Button onClick={() => this.refundTicket(ticket.id)}
                                        color={"warning"}>Rimborsa ticket</Button>
                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
            <div className={"col-md-6"}>
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">Modifica dati del ticket</CardTitle>
                        <div className={"row"}>
                            <div className="form-group col-md-6">
                                <label>Data inizio utilizzo</label>
                                <DatePicker selected={this.state.date_start_use} startDate={this.state.date_current}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "start")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data inizio utilizzo"}/>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Data fine utilizzo</label>
                                <DatePicker selected={this.state.date_end_use} minDate={this.state.date_start_use}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "end")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data fine utilizzo"}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Nominativo</label>
                                <Input type="text" value={this.state.tmp_input} onChange={(e) => {
                                    this.setState({tmp_input: e.target.value});
                                }} placeholder={"Nominativo del ticket"}/>
                            </div>
                            <div align={"center"} class={"col-md-12"}>
                                <Button onClick={() => this.editTicket()} color={"success"}>Salva</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {form_extra_data}
            </div>
        </div>
    }

    downloadTicketsClient = () => {
        let date_start = moment(this.state.date_start).unix();
        let date_end = moment(this.state.date_end).unix();

        global.notify("Dati scaricati", "success");
        console.log(this.state.society.id)
        windowOpen(global.baseUrl + 'admin/stats/ticket/download/' + this.state.society.id + "/" + date_start + "/" + date_end, "blank")
    }

    downloadTicketsClientExtraData = () => {
        let date_start = moment(this.state.date_start).unix();
        let date_end = moment(this.state.date_end).unix();

        global.notify("Dati scaricati", "success");
        console.log(this.state.society.id)
        windowOpen(global.baseUrl + 'admin/stats/ticket/download/extra_data/' + this.state.society.id + "/" + date_start + "/" + date_end, "blank")
    }

    renderReferrals = () => {
        let res = [];
        if (Object.keys(this.state.referrals).length === 0) {
            res.push(<div className={"col-md-12"}>
                <div class='alert alert-secondary' role='alert'>Non ci sono QRcode presenti</div>
            </div>);
        } else {
            for (let i in this.state.referrals) {
                let ref = this.state.referrals[i];
                res.push(<Card className={"col-md-4"}>
                        <CardBody>
                            <div className={"row"}>
                                <div>
                                    <img src={global.baseUrlImg + "/api/admin/referral/download/" + ref.id} alt=""
                                         max-height="150"
                                         width="150"/>
                                </div>
                                <div>
                                    <span>#{ref.id}</span><br/>
                                    <a href={ref.link}>{ref.link}</a>
                                    <p>Commissione: <b>{ref.percentage}%</b></p>
                                    <br/>
                                    <Button type="button" onClick={() => {
                                        this.setState({referral_edit: ref.id, referral_edit_percentage: ref.percentage});
                                    }} color="warning">Modifica</Button>
                                    <Button type="button" onClick={() => this.deleteReferral(ref.id)}
                                            color="danger">Elimina</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                );

            }
        }
        return res;
    }

    handleClose = () => this.setState({referral_edit: null, referral_edit_percentage: null});

    renderModalReferral = () => {
        return <Modal
            isOpen={this.state.referral_edit}
            backdrop="static"
            keyboard={false}>
            <ModalHeader>Modifica QRcode</ModalHeader>
            <ModalBody>
                <div className="form-group col-md-12">
                    <label>Percentuale di commissione</label>
                    <Input type="text" value={this.state.referral_edit_percentage} onChange={(e) => {
                        this.setState({referral_edit_percentage: e.target.value});
                    }} placeholder={"Percentuale di commissione"}/>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>{' '}
                <Button color="success"
                        onClick={() => this.editReferral(this.state.referral_edit, this.state.referral_edit_percentage)}>Salva</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Scheda ente"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="form-group">
                                            <Label for="society">Ente</Label>
                                            {this.renderSociety()}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <h5>Lista aree</h5>
                                <div className="form-group" hidden={!this.state.showAreas}>
                                    <div style={{height: '30vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rowsAreas}
                                            columns={this.state.columnsAreas}
                                            pagination
                                            pageSize={this.state.paginatorAreas.limit}
                                            rowsPerPageOptions={[this.state.paginatorAreas.limit]}
                                            rowCount={this.state.paginatorAreas.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChangeAreas}
                                            loading={this.state.paginatorAreas.loading}
                                        />
                                    </div>
                                </div>
                                <Button type="button" onClick={() => {
                                    this.setState({showAreas: !this.state.showAreas})
                                }} color="info">{this.state.showAreas ? "Nascondi" : "Mostra"}</Button>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Lista ticket</h5>
                                <div className="form-group" hidden={!this.state.showTickets}>
                                    <div style={{height: '30vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rowsTickets}
                                            columns={this.state.columnsTickets}
                                            pagination
                                            pageSize={this.state.paginatorTickets.limit}
                                            rowsPerPageOptions={[this.state.paginatorTickets.limit]}
                                            rowCount={this.state.paginatorTickets.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChangeTickets}
                                            loading={this.state.paginatorTickets.loading}
                                        />
                                    </div>
                                </div>
                                <FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/> Ticket nascosto in app<br/>
                                <FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/> Ticket disabilitato del
                                tutto<br/>

                                <Button type="button" onClick={() => {
                                    this.setState({showTickets: !this.state.showTickets})
                                }} color="info">{this.state.showTickets ? "Nascondi" : "Mostra"}</Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <h5>Lista ticket acquistati</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => this.loadList(this.state.society.id, 0)}
                                                        color={"success"}>Aggiorna</Button>
                                                <Button onClick={() => this.downloadTicketsClient()}
                                                        color={"primary"}>Scarica dati</Button>
                                                <Button onClick={() => this.downloadTicketsClientExtraData()}
                                                        color={"info"}>Scarica dati con campi extra</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca ticket"
                                                     className="form-control"
                                                     placeholder="Cerca ticket per nome, cognome o email dei clienti"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>

                                <div className="form-group">
                                    <div style={{height: '40vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rowsTicketsClient}
                                            columns={this.state.columnsTicketsClient}
                                            pagination
                                            pageSize={this.state.paginatorTicketsClient.limit}
                                            rowsPerPageOptions={[this.state.paginatorTicketsClient.limit]}
                                            rowCount={this.state.paginatorTicketsClient.countRows}
                                            paginationMode="server"
                                            loading={this.state.paginatorTicketsClient.loading}
                                        />
                                    </div>
                                </div>
                                {this.renderInfoTicket()}
                                {this.renderSessions()}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Lista referrals (QRcode)</h5>
                                <div className={"row"}>
                                    {this.renderReferrals()}
                                </div>
                                <hr/>
                                <h5>Aggiungi QRcode</h5>
                                <AvForm className="form" onValidSubmit={(e, v) => {
                                    this.handleValidSubmitReferral(e, v)
                                }} ref={c => (this.formReferral = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-4">
                                            <AvField name="referral_percentage" label="Percentuale per il passaparola"
                                                     className="form-control"
                                                     placeholder="Percentuale per il passaparola"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="success">Aggiungi</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        {this.renderModalReferral()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(SocietySummaryPage));