import React, {Component} from "react";
import {Row, Col, Card, CardTitle, CardText, CardBody, Container, Button, Badge, ButtonGroup, Input} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';
import {AvForm, AvField} from 'availity-reactstrap-validation';

import {
    editClientExtraDataTicket,
    editClientTicket,
    infoClientTicket,
    listTicketClientCoupon,
    refundClientTicket, statsCoupon, statsReferral
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {windowOpen} from "echarts/src/util/format";

var moment = require('moment');

class ReferralStatsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnsCoupon: [
                {
                    headerName: "[Ticket] Codice", field: "code", width: 180, renderCell: (params) => (
                        <Row>
                            <Col>[{params.id}] {params.value !== null ? params.value : "-"}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Società",
                    field: "society1",
                    width: 240,
                    valueGetter: (params) =>
                        (params.getValue(params.id, "society") !== null ? params.getValue(params.id, "society").name : "-")
                },
                {
                    headerName: "Commissione",
                    field: "referral_percentage1",
                    width: 160,
                    valueGetter: (params) =>
                        (params.getValue(params.id, "percentage") !== null ? params.getValue(params.id, "percentage") + "%" : "")
                },

                {
                    headerName: "Utilizzi",
                    field: "stats2",
                    width: 180,
                    valueGetter: (params) => params.getValue(params.id, "stats").count
                },
                {
                    headerName: "Totale",
                    field: "stats1",
                    width: 160,
                    valueGetter: (params) => "€" + params.getValue(params.id, "stats").total_fees
                },
                // {
                //     headerName: " ",
                //     width: 100,
                //     field: 'id',
                //     renderCell: (params) => (
                //         <Button onClick={() => window.location.href = '/coupon/edit/' + params.id} type="button"
                //                 color="warning">Modifica</Button>
                //     )
                // }
            ],

            rows: [],
            rowsCoupon: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorCoupon: {loading: true, countRows: 0, limit: 50},
            markers: [],
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            form_edit_ticket: {
                date_start: null,
                date_end: null,
            },
            search: null,
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_start_use: null,
            date_end_use: null,
            date_current: new Date(),
            date_start_coupon: (moment().subtract('1', "month").toDate()),
            date_end_coupon: new Date(),
            ticket: null,
            tmp_input: null
        };
    }

    setDate = (date, type) => {
        console.log(date);
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    setDateTicket = (date, type) => {
        let date_start = this.state.date_start_use;
        let date_end = this.state.date_end_use;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit_ticket = this.state.form_edit_ticket;
        form_edit_ticket.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit_ticket.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start_use: date_start, date_end_use: date_end, form_edit_ticket: form_edit_ticket});
    }

    async componentDidMount() {
        this.loadListCoupon(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        listTicketClientCoupon(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    loadListCoupon = (page) => {
        let paginator = this.state.paginatorCoupon;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        statsReferral(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsCoupon: [], paginatorCoupon: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsCoupon: result.list, paginatorCoupon: paginator});
            }
        })
    }

    async refundTicket(id) {
        let res = await refundClientTicket(id);
        if (res) {
            global.notify("Rimborso effettuato con successo", "success");
            this.setState({ticket: null}, () => this.loadList(0));
        } else {
            global.notify("Errore con il rimborso", "danger");
            this.loadList(0)
        }
    }

    async infoButton(params) {
        let res = await infoClientTicket(params);
        this.setState({ticket: res.ticket});

        let ticket = res.ticket;
        let form_edit = {date_start: null, date_end: null};
        if (ticket.date_start_use !== null) {
            this.setState({date_start_use: moment(ticket.date_start_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_start = moment(ticket.date_start_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        if (ticket.date_end_use !== null) {
            this.setState({date_end_use: moment(ticket.date_end_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_end = moment(ticket.date_end_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        let nickname = null;
        if (ticket.nickname !== null)
            nickname = res.ticket.nickname;
        this.setState({ticket: ticket, form_edit_ticket: form_edit, tmp_input: nickname});
    }

    async handleValidSubmit(event, values, edit = false) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList(0));
    }

    async handleEditExtraDataSubmit(event, values, edit = false) {
        editClientExtraDataTicket({id: this.state.ticket.id, extra_data: JSON.stringify(values)}).then((res) => {
            if (typeof res.error !== 'undefined')
                global.notify("I dati extra non sono stati modificati: " + res.error, "danger");
            else {
                global.notify("Ticket modificato con successo", "success");
                this.infoButton(this.state.ticket.id);
            }
        })
    }

    downloadTicket = (id) => {
        global.notify("Ticket scaricato", "success");
        windowOpen(global.baseUrl + 'admin/ticket/download/' + id, "blank")
    }

    editTicket = async () => {
        let data = {
            id: this.state.ticket.id,
            nickname: this.state.tmp_input,
            date_start: this.state.form_edit_ticket.date_start,
            date_end: this.state.form_edit_ticket.date_end
        };

        console.log(data);
        let res = await editClientTicket(data);
        if (typeof res.error !== 'undefined')
            global.notify("Il ticket non è stato modificato: " + res.error, "danger");
        else {
            global.notify("Ticket modificato con successo", "success");
            this.infoButton(this.state.ticket.id);
        }
    }

    renderInfoTicket = () => {
        if (this.state.ticket === null)
            return;
        let ticket = this.state.ticket;
        let payment = null;
        if (ticket.paypal !== null)
            payment = <div>
                <hr/>
                <b>Pagato con PayPal</b><br/>Codice
                transazione: {ticket.paypal.sale_id}<br/>Token: {ticket.paypal.token}</div>
        if (ticket.stripe !== null)
            payment = <div>
                <hr/>
                <b>Pagato con Stripe</b><br/>Codice transazione: {ticket.stripe.stripe_id}<br/>Codice
                cliente: {ticket.stripe.client_secret}</div>

        let extra_data = [];
        if (Object.keys(ticket.extra_data).length > 0)
            extra_data.push(<hr/>);
        for (let i in ticket.extra_data) {
            let data = ticket.extra_data[i];
            extra_data.push(<b>{data.name}:</b>);
            extra_data.push(<span> {data.value}</span>);
            extra_data.push(<br/>);
        }
        let total = 0;
        let summary = [<hr/>];
        summary.push(<><b>Ticket:</b> €{ticket.price.toFixed(2)}<br/></>);
        total = ticket.price;
        if (ticket.service_fee !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Commissione:</b> €{ticket.service_fee.toFixed(2)}</span></>);
            total += ticket.service_fee;
        }
        if (ticket.discount_price !== null) {
            let code = "";
            if (ticket.coupon !== null)
                code = " (" + ticket.coupon.code + ")";
            summary.push(<><span style={{textWrap: "nowrap"}}><b>Sconto:</b> -€{ticket.discount_price.toFixed(2)} {code}</span><br/></>);
            total -= ticket.discount_price;
        }
        if (ticket.fees !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Transazione pagamento:</b> €{ticket.fees.toFixed(2)}</span><br/></>);
        }
        summary.push(<><b>Totale:</b> €{total.toFixed(2)}<br/></>);
        let form_extra_data = null;
        if (Object.keys(ticket.ticket.extra_data).length > 0) {
            let inputs = [];
            for (let i in ticket.ticket.extra_data) {
                let extra_data = ticket.ticket.extra_data[i];
                let value = "";
                for (let x in ticket.extra_data) {
                    if (ticket.extra_data[x].id === extra_data.id)
                        value = ticket.extra_data[x].value;
                }
                inputs.push(<div className="form-group col-md-12">
                    <AvField name={extra_data.id} label={extra_data.name} value={value}
                             className="form-control"
                             placeholder={extra_data.name}
                             type="text"/>
                </div>)
            }

            form_extra_data = <Card><CardBody><CardTitle tag="h5">Modifica dati extra del ticket</CardTitle>
                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                    this.handleEditExtraDataSubmit(e, v, this.state.flag_edit)
                }} ref={c => (this.form = c)}>
                    <div className={"row"}>
                        {inputs}
                        <div className="form-group col-md-12" align={"center"}>
                            <Button type="submit" color="success">Salva</Button>
                        </div>
                    </div>
                </AvForm>
            </CardBody></Card>
        }

        return <div className={"row"}>
            <div className={"col-md-6"}>
                <Card body>
                    <CardTitle tag="h5">Ticket #{ticket.id}</CardTitle>
                    <CardText>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <b>Nome ticket:</b> {ticket.ticket.name}<br/>
                                Data acquisto: {ticket.date_purchase}<br/>
                                Data utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                                {ticket.client !== null ? "Utente: " + ticket.client.firstname + " " + ticket.client.lastname : ""}<br/>
                                {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                                {summary}
                                {extra_data}
                                {payment}
                                <hr/>
                                <b>Ente:</b> {ticket.ticket.society !== null ? ticket.ticket.society.name : "-"}<br/>
                                <b>Area:</b> {ticket.ticket.area !== null ? ticket.ticket.area.name : "-"} <br/>
                                {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            </div>
                            <div className={"col-md-6"} align={"right"}>
                                <Button onClick={() => this.downloadTicket(ticket.id)}
                                        color={"danger"}>Scarica pdf</Button>
                                <br/>
                                <br/>
                                <Button onClick={() => this.refundTicket(ticket.id)}
                                        color={"warning"}>Rimborsa ticket</Button>
                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
            <div className={"col-md-6"}>
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">Modifica dati del ticket</CardTitle>
                        <div className={"row"}>
                            <div className="form-group col-md-6">
                                <label>Data inizio utilizzo</label>
                                <DatePicker selected={this.state.date_start_use} startDate={this.state.date_current}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "start")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data inizio utilizzo"}/>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Data fine utilizzo</label>
                                <DatePicker selected={this.state.date_end_use} minDate={this.state.date_start_use}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "end")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data fine utilizzo"}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Nominativo</label>
                                <Input type="text" value={this.state.tmp_input} onChange={(e) => {
                                    this.setState({tmp_input: e.target.value});
                                }} placeholder={"Nominativo del ticket"}/>
                            </div>
                            <div align={"center"} class={"col-md-12"}>
                                <Button onClick={() => this.editTicket()} color={"success"}>Salva</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {form_extra_data}
            </div>
        </div>
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    handlePageChangeCoupon = (params) => {
        this.loadListCoupon(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Statistiche referral"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli le date in cui vuoi visualizzare le statistiche dei referral</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_start_coupon}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_end_coupon}
                                                    minDate={this.state.date_start_coupon} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => this.loadListCoupon(0)}
                                                        color={"success"}>Aggiorna</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="form-group">
                                    <div style={{height: '50vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rowsCoupon}
                                            columns={this.state.columnsCoupon}
                                            pagination
                                            pageSize={this.state.paginatorCoupon.limit}
                                            rowsPerPageOptions={[this.state.paginatorCoupon.limit]}
                                            rowCount={this.state.paginatorCoupon.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChangeCoupon}
                                            loading={this.state.paginatorCoupon.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(ReferralStatsPage));
